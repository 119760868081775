<template>
  <container id="solutions" links="true" :title="t('title')" subtitle="">
      <div class="solutions">
        <div :id="`sol-${item.icon}`" v-for="item in data" :key="item.svg" class="solutions__item">
                <div class="solutions__logo">
                    <Svg :icon="item.icon" className="solutions__logo--svg"/>
                </div>
                <div class="solutions__content">
                    <h3>
                        {{item.title}}
                    </h3>
                    <p>
                        {{item.text}}
                    </p>
                </div>
            </div>
      </div>
  </container>
</template>

<script>
import Svg from './utils/Svg'
import Container from './Container.vue'
import {dataSolutions} from '../composables/solutions';

import {useI18n} from 'vue-i18n';

import { computed } from 'vue';
export default {
  components: { Container, Svg },
  setup(){
    const {t} = useI18n({
        messages:{
            en: require('../locales/en/solutions.json'),
            fr: require('../locales/fr/solutions.json')
        },
    })
      const data = computed(() => dataSolutions(t));
      return {data, t};
  }

}
</script>

<style lang="scss">
@import '../style/main';

.solutions{
    border: 1px solid currentColor;
    border-radius: 5px;
    box-shadow: $shadow;

    padding: 4rem;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respond(phone){
        flex-direction: column;
        padding: 2.5rem;
    }

    &__item{

        flex: 0 0 48%;
        display: flex;

        margin-bottom: 3rem;

        @include respond(small){
            flex-direction: column;
        }
        
    }

    &__content{
        flex: 1;

        & h3{
            margin-bottom: .6rem;
            text-transform: uppercase;
            letter-spacing: .1rem;
        }

        & p{
            text-align: justify;
        }
    }
    &__logo{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        border-radius: 3px;
        border:1px solid currentColor;
        box-shadow: 3px 3px 0 currentColor;
        margin-right: 2rem;
        &--svg{
            @include svg(2.4rem);
        }

         @include respond(small){
            margin-right: 0rem;
            margin-bottom: 1.6rem;
        }
    }
}

</style>